import React, { useEffect, useState } from "react";
import BgVideo from "../assets/ElementsMA/Video/bg.mp4";
import LeftAlignedTimeline from "../components/CustomizedTimeline";
import arrow from "../assets/Backend/Icons/arrow.png";

import MainFooter from "../components/MainFooter";
import Sec2Img from "../assets/ElementsMA/Images/Sec2Img.jpg";
import Elasticsearch from "../assets/ElementsMA/Icons/Elasticsearch.png";
import Logstash from "../assets/ElementsMA/Icons/Logstash.png";
import Kibana from "../assets/ElementsMA/Icons/Kibana.png";

import AMNavbar from "../components/AMNAvbar";

import AOS from "aos";
import "aos/dist/aos.css";
const Monitoring = () => {
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    let scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 500) {
      setScroll(true);
      // console.log(scrollValue);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    AOS.init({ duration: "1000" });

    window.scroll(0, 0);
  }, []);
  let services = [
    {
      id: 1,
      title: "Elasticsearch",
      description: `Discover Elasticsearch, a distributed, RESTful search and analytics engine designed to tackle diverse use cases. As
      the foundational component of the Elastic Stack, it acts as the centralized hub for storing your data. Experience
      rapid search speeds, finely-tuned relevancy rankings, and scalable analytics functionalities that seamlessly adapt
      to your requirements.`,
      img: Elasticsearch,
    },

    {
      id: 2,
      title: "Logstash",
      description: ` Delve into Logstash, a versatile server-side data processing pipeline offered as an open-source solution. It
      efficiently collects data from various sources, applies transformations, and finally routes it to your preferred
      destination, referred to as your "stash". Logstash streamlines your data workflows, allowing you to extract
      valuable insights from diverse data sources with ease.`,
      img: Logstash,
    },
    {
      id: 3,
      title: "Kibana",
      description: `Delve into Logstash, a versatile server-side data processing pipeline offered as an open-source solution. It
      efficiently collects data from various sources, applies transformations, and finally routes it to your preferred
      destination, referred to as your "stash". Logstash streamlines your data workflows, allowing you to extract
      valuable insights from diverse data sources with ease.
      `,
      img: Kibana,
    },
  ];

  return (
    <>
      <div className="bg-[#D2D2DB] ">
        {/* ====================== >>> Section 1 Starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB] w-screen overflow-hidden ">
          <div
            className={`sticky top-0  z-10   ${
              scroll ? " bg-[#0000005a]" : "bg-transparent max-sm:bg-[#2787b7]"
            }`}
          >
            <AMNavbar />
          </div>
          <div className="relative -top-[6rem] left-0 ">
            <video
              src={`${BgVideo}`}
              autoPlay
              loop={true}
              muted
              className=" object-cover h-[80vh] w-screen"
            ></video>
          </div>
          <div
            className="container mx-auto sm:w-[90%]  relative -top-[10rem] left-0 sm:h-10 h-fit "
            data-aos="fade-left"
          >
            <h1 className="text-white font-extrabold mx-4 text-5xl lg:text-6xl px-8    ">
              Monitoring
            </h1>
            <h1 className="text-black  font-bold mx-4 text-5xl lg:text-6xl mt-4 lg:mt-0 px-8">
              & Analytics
            </h1>
            <p className="text-black font-bold mx-4   text-2xl px-8 mt-2  ">
              Providing real-time monitoring and insightful analytics services
              to empower data-driven decision-making and enhance performance
            </p>
          </div>
        </div>
        {/* ====================== >>> Section 1 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 2 Starts Here <<<< ==============================*/}

        <div className="bg-white h-full ">
          <div
            className="container w-screen  bg-white  mt-5   items-center 
          justify-center  overflow-hidden flex flex-col sm:flex-row mx-auto my-6 p-6"
          >
            <img
              data-aos="fade-up"
              src={Sec2Img}
              alt="ImageHere"
              className="w-[150%] sm:w-[35%]   h-auto object-cover "
            />

            <div className=" container mx-8  bg ">
              <div className="flex flex-col items-start justify-center">
                <h1 className="pl-2 pt-3 text-sm xl:text-xl  futura_heavy  text-[#053F74]">
                  Welcome to our Monitoring and Analytics platform, where
                  precision meets insight.
                </h1>
                <br />
                <h1 className="  text-sm xl:text-xl  text-black">
                  With our state-of-the-art Monitoring tools, you can keep a
                  vigilant eye on every aspect of your operations in real-time.
                  From website performance to network health, we provide
                  comprehensive monitoring solutions that deliver actionable
                  insights.
                </h1>
                <br />
                <h1 className=" text-sm xl:text-xl  text-black">
                  Our team of experts is dedicated to understanding your unique
                  challenges and crafting tailored solutions that meet your
                  specific needs. With our user-friendly interface and intuitive
                  dashboards, accessing and interpreting your data has never
                  been easier.
                </h1>
              </div>
            </div>
          </div>
          <div
            className="container w-screen  bg-white pt-0   items-center 
          justify-center  overflow-hidden flex flex-col sm:flex-row  p-6 "
          >
            <div className=" container mx-8 ">
              <div className="flex flex-col  justify-center">
                <h1 className="my-1 text-sm xl:text-xl  maven-pro-regular  text-black">
                  Our advanced Analytics capabilities unlock the true value of
                  your data. By employing cutting-edge techniques and
                  algorithms, we transform raw data into meaningful insights
                  that drive informed decision-making. Whether you're analyzing
                  customer behavior, optimizing resource allocation, or
                  forecasting trends, our analytics services give you the
                  competitive edge you need to thrive in today's data-driven
                  world.
                </h1>
                <br />
                <h1 className="my-1 text-sm xl:text-xl  text-black">
                  Experience the power of proactive and actionable analytics
                  with our comprehensive suite of services. Empower your
                  business to adapt, evolve, and succeed in an increasingly
                  complex digital landscaps
                </h1>
                <br />

                <h1 className="my-1  text-sm xl:text-xl  text-center futura_heavy  text-[#053F74]">
                  Delve into to a new era of data-driven decision making
                </h1>
              </div>
            </div>
          </div>
        </div>

        {/* ====================== >>> Section 2 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 3 Starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB] h-full w-auto">
          <div className="flex flex-col items-center pt-6 gap-4 p-4 ">
            {services.map((service, index) => {
              return (
                <>
                  <div
                    className="flex flex-col w-auto p-2  items-center rounded-2xl sm:flex-row bg-white sm:w-[80%]"
                    data-aos="fade-left"
                  >
                    <img
                      src={service.img}
                      className="w-[8rem] h-auto "
                      alt="imageHere"
                    />
                    <div className="w-auto p-4">
                      <h1 className="text-black maven-pro-bold text-xl ">
                        {service.title}
                      </h1>
                      <h1 className="text-black maven-pro-regular  ">
                        {service.description}
                      </h1>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        {/* ====================== >>> Section 3 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 4 Starts Here <<<< ============================== */}
        <div className="MNTSec4Image   ">
          <div className="bg-[#053f74]/70 ">
            <div className="h-auto flex flex-col items-center justify-center ">
              <h1
                className="maven-pro-bold text-center pt-24 text-white text-4xl "
                data-aos="fade-right"
              >
                Why ELK is Important?
              </h1>

              <div className="flex sm:flex-col flex-col  items-center justify-center sm:w-[85%]  ">
                <ul className="  m-16 mt-4 list-disc text-sm sm:text-xl maven-pro-regular  ">
                  <li data-aos="fade-down" className=" text-white p-2">
                    The ELK stack meets the growing demand for efficient log
                    analytics solutions, empowering developers and DevOps
                    engineers to extract valuable insights for failure
                    diagnosis, application performance optimization, and
                    infrastructure monitoring.
                  </li>
                  <li data-aos="fade-down" className=" text-white p-2">
                    With the migration of IT infrastructure to public clouds
                    becoming increasingly prevalent, there arises a critical
                    need for a comprehensive log management and analytics
                    platform to monitor this evolving landscape and handle
                    various types of logs, including server logs, application
                    logs, and clickstreams
                  </li>
                  <li data-aos="fade-down" className=" text-white p-2">
                    The ELK stack empowers you to centralize logs from across
                    your systems and applications, conduct in-depth analysis,
                    and generate visualizations crucial for application and
                    infrastructure monitoring, expedited troubleshooting,
                    security analytics, and beyond.
                  </li>
                  <li data-aos="fade-down" className=" text-white p-2">
                    This comprehensive stack serves as a versatile solution,
                    addressing an array of challenges such as log analytics,
                    document search, and security information and event
                    management (SIEM).
                  </li>
                  <li data-aos="fade-down" className=" text-white p-2">
                    Whether you're striving to enhance operational efficiency,
                    strengthen security posture, or streamline data management
                    processes, the ELK stack provides the essential components
                    needed to tackle these tasks effectively
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* ====================== >>> Section 4 End Here <<<< ============================== */}
        {/* ====================== >>> Section 5 Starts Here <<<< ============================== */}
        <div className=" bg-[#D2D2DB] pt-12   h-auto  py-12 ">
          <h1 className="text-4xl text-center   text-black font-semibold  futura_heavy  w-11/12 mx-auto">
            How Amartech Organize A Smooth Workflow For Their Clients?
          </h1>
          <div className="pt-8 container sm:w-[95%] sm:mx-auto mx-2">
            <LeftAlignedTimeline />
          </div>
        </div>
        {/* ====================== >>> Section 5 Ends Here <<<< ==============================
             {/* ====================== >>> Section 6 starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB]   pt-4 pb-5 grid grid-col-1 justify-center">
          <div className="bg-[#053F74]  xl:w-[750px] rounded-3xl p-4  flex flex-col items-center justify-center px-6">
            <h1 className="text-4xl max-sm:text-3xl text-center text-[#D2D2DB] font-bold pt-12 max-sm:pt-8 maven-pro-regular ">
              Want To Shape Your Business?
            </h1>
            <div className=" max-sm:h-36 rounded-md pt-8 max-sm:pt-4    ">
              <button className=" bg-[#d2d2db]  rounded-2xl  pl-5  text-2xl max-sm:text-2xl   flex flex-row text-[#FFFFFF] tomorrow-regular">
                Start Conversation Now
                <span>
                  <img src={arrow} alt="imageHere" className="w-14 pl-4" />
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* ====================== >>> Section 6 Ends Here <<<< ============================== */}
        <div className=" bg-[#053F74]   p-4 w-100  h-auto mx-auto   mySubContainerClass">
          <MainFooter />
        </div>
      </div>
    </>
  );
};

export default Monitoring;
