import React, { useEffect, useState } from "react";
import BgVideo from "../assets/DigitalTransformation/Video/bg.mp4";
import LeftAlignedTimeline from "../components/CustomizedTimeline";
import arrow from "../assets/Backend/Icons/arrow.png";

import MainFooter from "../components/MainFooter";
import Sec2Img from "../assets/DigitalTransformation/Image/DT 1.jpg";
import Sec2Img2 from "../assets/DigitalTransformation/Image/DT 2.jpg";
import gap from "../assets/DigitalTransformation/Image/IdentifyGaps.png";
import analysis from "../assets/DigitalTransformation/Image/Analysis.png";
import blueprint from "../assets/DigitalTransformation/Image/Blueprint.png";
import journey from "../assets/DigitalTransformation/Image/Journey.png";

import UiUx from "../assets/DigitalTransformation/Icons/UiUX.png";
import MobileDev from "../assets/DigitalTransformation/Icons/MobileDev.png";
import IOT from "../assets/DigitalTransformation/Icons/IOT.png";
import ProcessConsult from "../assets/DigitalTransformation/Icons/ProcessConsult.png";
import LegacyModern from "../assets/DigitalTransformation/Icons/LegacyModern.png";
import CloudComp from "../assets/DigitalTransformation/Icons/CloudComp.png";

import AMNavbar from "../components/AMNAvbar";

import AOS from "aos";
import "aos/dist/aos.css";

const DigitalTransformation = () => {
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    let scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 500) {
      setScroll(true);
      // console.log(scrollValue);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    AOS.init({ duration: "1000" });

    window.scroll(0, 0);
  }, []);
  let services = [
    {
      id: 1,
      title: "UI/UX Designs",
      description: `Engaging, flexible, and purpose-driven designs customized to
 reflect your business persona.`,
      img: UiUx,
    },

    {
      id: 2,
      title: " Mobile App and Web Development",
      description: ` Interfaces prioritizing ROI and consumer experience, seamlessly
 functional across various platforms`,
      img: MobileDev,
    },
    {
      id: 3,
      title: "IoT System Engineering",
      description: `Expand globally with IoT technology, connecting people and
 devices to bridge geographical distances.`,
      img: IOT,
    },
    {
      id: 4,
      title: "Process Consulting",
      description: ` Assess your current business processes, pinpoint obstacles, and
 develop intelligent digital solutions.`,
      img: ProcessConsult,
     
    },
    {
      id: 5,
      title: " Legacy Modernization",
      description: `Revamping legacy systems through the modernization of outdated
 infrastructure and efficiently resolving any associated issues`,
      img: LegacyModern,
    },
    {
      id: 6,
      title: " Cloud Computing and Migration",
      description: ` Enhance your enterprise's scalability, ensure seamless business continuity,
 and maximize cost-effectiveness by migrating to the cloud`,
      img: CloudComp,
    },
  ];

  return (
    <>
      <div className="bg-[#D2D2DB] ">
        {/* ====================== >>> Section 1 Starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB] w-screen overflow-hidden ">
          <div
            className={`sticky top-0  z-10   ${
              scroll ? " bg-[#0000005a]" : "bg-transparent max-sm:bg-[#2787b7]"
            }`}
          >
            <AMNavbar />
          </div>
          <div className="relative -top-[6rem] left-0 ">
            <video
              src={`${BgVideo}`}
              autoPlay
              loop={true}
              muted
              className=" object-cover h-[80vh] w-screen"
            ></video>
          </div>
          <div
            className="container mx-auto sm:w-[90%]  relative -top-[8.5rem] md:-top-[10rem] left-0 sm:h-10 h-fit "
            data-aos="fade-right"
          >
            <h1 className="text-white font-extrabold mx-4 text-5xl lg:text-6xl px-8">
              Digital Transformation
            </h1>
            <h1 className="text-black  font-bold mx-4 text-5xl lg:text-6xl mt-4 lg:mt-0 px-8">
              & Consultation
            </h1>
            <p className="text-black font-bold mx-4   text-2xl px-8 mt-2  ">
              Guiding businesses through comprehensive digital transformation
              with tailored consultation services, optimizing operations and
              enhancing competitiveness in the digital age.
            </p>
          </div>
        </div>
        {/* ====================== >>> Section 1 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 2 Starts Here <<<< ==============================*/}

        <div className="bg-white h-full ">
          <div
            className="container w-screen  bg-white flex flex-col  mt-5   items-center 
          justify-center  overflow-hidden  sm:flex-row mx-auto my-6 p-6"
          >
            <img
              data-aos="fade-left"
              src={Sec2Img}
              alt="ImageHere"
              className="w-[150%] sm:w-[35%]  pt-10 h-auto object-cover "
            />

            <div className=" container mx-8  bg " data-aos="fade-left">
              <div className="flex flex-col items-start justify-center">
                <h1 className=" text-sm xl:text-xl  maven-pro-regular  text-black mt-6">
                  Unlock the potential for product innovation, enhance
                  operational efficiency, and elevate customer experiences with
                  our cutting-edge Digital Transformation Services
                </h1>
                <br />
                <h1 className="my-1 text-sm xl:text-xl  text-black">
                  We offer consulting services to help businesses adapt to the
                  digital age, identifying opportunities for improvement, and
                  devising strategies for successful transformation.
                </h1>
                <br />
                <h1 className="my-1 text-sm xl:text-xl futura_heavy text-[#053F74]">
                  Embrace a future with Amar Tech, where possibilities are
                  limitless and success is redefined in the digital realm.
                </h1>
              </div>
            </div>
          </div>
          <div
            className="container w-screen  bg-white pt-0   items-center 
          justify-center  overflow-hidden flex flex-col sm:flex-row  p-6 "
          >
            <div className=" container mx-8 ">
              <div
                className="flex flex-col items-start justify-center"
                data-aos="fade-right"
              >
                <h1 className="my-1 text-sm xl:text-xl  maven-pro-regular  text-black">
                  Our expert team empowers your business to embrace digital
                  evolution, enabling seamless innovation, streamlined
                  operations, and optimized customer interactions.
                </h1>
                <br />
                <h1 className="my-1 text-sm xl:text-xl  text-black">
                  We specialize in guiding businesses towards unparalleled
                  innovation, operational excellence, and customer-centricity
                  through our bespoke digital transformation solutions
                </h1>
                <br />
                <h1 className="my-1 text-sm xl:text-xl futura_heavy text-[#053F74]">
                  Experience the pinnacle of transformational expertise tailored
                  to meet your unique business goals and drive sustainable
                  growth in today's dynamic market landscape.
                </h1>
              </div>
            </div>
            <img
              data-aos="fade-right"
              src={Sec2Img2}
              alt="ImageHere"
              className="w-[150%] sm:w-[35%]  pt-5 pb-2 h-auto object-cover "
            />
          </div>
        </div>

        {/* ====================== >>> Section 2 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 3 Starts Here <<<< ============================== */}

        <div className="bg-white  mx-auto w-screen mt-20 py-12">
          <h1 className="text-2xl md:text-3xl text-black maven-pro-bold text-center px-4">
            Step into the Digital Era – Digital Transformation Consulting
            Service
          </h1>
          <div className="mx-auto  w-[85%] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 p-8 bg-white mt-16 ">
            <div className="flex flex-col items-center justify-around">
              <div data-aos="fade-right">
                <h1 className="maven-pro-bold text-2xl text-black">
                  Identify Gaps
                </h1>
                <p className="maven-pro-regular text-black">
                  Defining a client's long-term objective, conducting
                  comprehensive industry and competition analysis, and
                  recognising unstructured departments and challenging
                  stakeholders.
                </p>
              </div>
              <div data-aos="fade-right">
                <h1 className="maven-pro-bold text-2xl text-black">
                  Creating a Blueprint
                </h1>
                <p className="maven-pro-regular text-black">
                  Strategically prioritizing key objectives, developing a
                  futuristic and scalable transformation plan, and incorporating
                  leading industry practices while ensuring compliance with
                  standards
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 items-center justify-center gap-4">
              <img
                src={gap}
                alt="imageHere"
                data-aos="zoom-in"
                className="w-[90%]"
              />
              <img
                src={analysis}
                alt="imageHere"
                data-aos="zoom-in"
                className="w-[90%]"
              />
              <img
                src={blueprint}
                alt="imageHere"
                data-aos="zoom-in"
                className="w-[90%]"
              />
              <img
                src={journey}
                alt="imageHere"
                data-aos="zoom-in"
                className="w-[90%]"
              />
            </div>
            <div className="flex flex-col items-center justify-around">
              <div data-aos="fade-left">
                <h1 className="maven-pro-bold text-2xl text-black">
                  Analysis and Solution Strategy
                </h1>
                <p className="maven-pro-regular text-black">
                  Evaluating organizational capabilities, identifying gaps
                  between current practices and desired goals, and recommending
                  suitable Digital Transformation tools
                </p>
              </div>
              <div data-aos="fade-left">
                <h1 className="maven-pro-bold text-2xl text-black">
                  Optimising User Journey
                </h1>
                <p className="maven-pro-regular text-black">
                  Crafting a strong brand identity for your enterprise,
                  enhancing consumer experiences by identifying and resolving
                  technology gaps, and swiftly identifying red flags and
                  troubleshooting.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* ====================== >>> Section 3 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 4 Starts Here <<<< ============================== */}
        <div className="DTBGSec4Image h-auto lg:h-screen w-screen flex flex-col items-center justify-center">
          <div className="bg-white/5 h-full w-screen  py-6 flex flex-col items-center justify-around">
            <div>
              <h1
                className="futura_heavy text-center text-white text-2xl md:text-4xl py-2 px-4"
                data-aos="fade-right"
              >
                Shape your Future - Digital Business Transformation Services
              </h1>
              <p
                className="maven-pro-medium text-center text-white py-2 text-md px-4"
                data-aos="fade-right"
              >
                Empower your business with robust digital transformation
                services crafted to deliver top tier experiences leveraging
                cutting-edge digital evolution tools.
              </p>
            </div>
            <div className="container grid  grid-cols-1 sm:grid-cols-2 gap-8 w-[85%] pt-8 items-center justify-center ">
              {services?.map((service) => {
                return (
                  <div key={service.id} data-aos="zoom-in">
                    <div className="flex flex-row items-center justify-center">
                      <img
                        src={service.img}
                        alt="imageHere"
                        className="w-[15%] h-[15%] sm:w-[10%] sm:h-[10%]"
                      />
                      <div className="mx-4">
                        <h1 className="maven-pro-bold text-xl">
                          {service.title}
                        </h1>
                        <p className="maven-pro-regular">
                          {service.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* ====================== >>> Section 4 End Here <<<< ============================== */}
        {/* ====================== >>> Section 5 Starts Here <<<< ============================== */}
        <div className=" bg-[#D2D2DB] pt-12   h-auto  py-12 ">
          <h1 className="text-4xl text-center   text-black font-semibold  futura_heavy  w-11/12 mx-auto">
            How Amartech Organize A Smooth Workflow For Their Clients?
          </h1>
          <div className="pt-8 container sm:w-[95%] sm:mx-auto mx-2">
            <LeftAlignedTimeline />
          </div>
        </div>
        {/* ====================== >>> Section 5 Ends Here <<<< ==============================
             {/* ====================== >>> Section 6 starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB]   pt-4 pb-5 grid grid-col-1 justify-center">
          <div className="bg-[#053F74]  xl:w-[750px] rounded-3xl p-4  flex flex-col items-center justify-center px-6">
            <h1 className="text-4xl max-sm:text-3xl text-center text-[#D2D2DB] font-bold pt-12 max-sm:pt-8 maven-pro-regular ">
              Want To Shape Your Business?
            </h1>
            <div className=" max-sm:h-36 rounded-md pt-8 max-sm:pt-4    ">
              <button className=" bg-[#d2d2db]  rounded-2xl  pl-5  text-2xl max-sm:text-2xl   flex flex-row text-[#FFFFFF] tomorrow-regular">
                Start Conversation Now
                <span>
                  <img src={arrow} alt="imageHere" className="w-14 pl-4" />
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* ====================== >>> Section 6 Ends Here <<<< ============================== */}
        <div className=" bg-[#053F74]   p-4 w-100  h-auto mx-auto   mySubContainerClass">
          <MainFooter />
        </div>
      </div>
    </>
  );
};

export default DigitalTransformation;
