import React, { useEffect } from "react";
// import { TiContacts } from "react-icons/ti";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ContactFormIcon from "../assets/Contact FormIcon.png";
import EmailIcon from "../assets/EmailIcon.png";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

// import { Mail } from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Phone } from "lucide-react";
const people = [
  {
    id: 1,
    name: "---- Select Service ----",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Backend Development",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Frontend Development",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 4,
    name: "Database Management",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 5,
    name: "Monitoring And Analytics",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 6,
    name: "Digital Transforamtion and Consultation",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Contactus = () => {
  useEffect(() => {
    AOS.init({ duration: "1000" });
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selected, setSelected] = useState(people[0]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#F8F8F8",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className=" mx-auto lg:py-8 md:py-4 ">
        <h1 className=" flex items-center justify-center mt-28  font-bold  text-4xl sm:text-5xl lg:text-6xl bg-[#4BB5DE]  py-8 w-full">
          <span className=" p-4" data-aos="slide-down">
            <TfiHeadphoneAlt size={80} />
          </span>
          <span className=" p-4 headerFont" data-aos="slide-down">
            Get in Touch With Us
          </span>
        </h1>
        <div className="mx-12 pt-28 text-center gap-2 text-white grid  lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 px-4 items-center justify-evenly">
          <div
            className="flex flex-col items-center justify-center"
            data-aos="zoom-in"
          >
            <div
              style={{
                paddingBottom: 30,
              }}
            >
              <Phone color="#4BB5DE" size={130} className="mx-2" />
            </div>
            {/* <img
              src={ContactFormIcon}
              alt=""
              className="w-[60%] md:w-[35%] lg:w-[25%]"
              onClick={handleOpen}
            /> */}

            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={style}
                className="rounded-lg h-auto md:w-[40%] w-[85%]   my-18"
              >
                <Typography id="modal-modal-description">
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                          Select Service
                        </Listbox.Label>
                        <div className="relative mt-2">
                          <Listbox.Button className="relative w-full cursor-default rounded-full bg-white  pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#4BB5DE] sm:text-sm sm:leading-6 p-2 py-3">
                            <span className="flex items-center">
                            
                              <span className="ml-3 block truncate text-black">
                                {selected.name}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-4">
                              <ChevronUpDownIcon
                                className="h-4 w-4 bg-[#4BB5DE]"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {people.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-[#4BB5DE] text-black"
                                        : "text-black",
                                      "relative cursor-default select-none py-2 pl-3 pr-9 "
                                    )
                                  }
                                  value={person}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex items-center">
                                       
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold "
                                              : "font-normal",
                                            "ml-3 block truncate text-black"
                                          )}
                                        >
                                          {person.name}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-black"
                                              : "text-black",
                                            "absolute inset-y-0 right-0 flex items-center pr-4 "
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5 "
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                  <form action="" method="post">
                    <div className="grid  grid-cols-2 gap-5  md:pt-7 pt-2">
                      <input
                        placeholder="Full Name*"
                        className="rounded-full text-black p-2 py-3 shadow-md shadow-slate-200  ps-8"
                        required
                      />
                      <input
                        placeholder="Country*"
                        required
                        className="rounded-full text-black p-2 py-3 shadow-md shadow-slate-200  ps-8"
                      />
                    </div>
                    <div className="grid  grid-cols-2 gap-5  md:pt-7 pt-2">
                      <input
                        placeholder="Company Name*"
                        className="rounded-full text-black p-2 py-3 shadow-md shadow-slate-200  ps-8"
                        required
                      />
                      <input
                        placeholder="Job Title*"
                        className="rounded-full text-black p-2 py-3 shadow-md shadow-slate-200  ps-8"
                        required
                      />
                    </div>
                    <div className="grid  grid-cols-2 gap-5  md:pt-7 pt-2">
                      <input
                        className="rounded-full text-black p-2 py-3 shadow-md shadow-slate-200  ps-8"
                        placeholder="Email*"
                        variant="outlined"
                        required
                      />
                      <input
                        className="rounded-full text-black p-2 py-3 shadow-md shadow-slate-200  ps-8"
                        placeholder="Phone Number*"
                        variant="outlined"
                        required
                      />
                    </div>
                    <div className="md:pt-5 pt-2">
                      <textarea
                        placeholder="Add Your Message here"
                        multiline
                        rows={3}
                        className="rounded-md text-black p-2 py-3 w-full shadow-md shadow-slate-200 ps-8"
                        required
                        fullWidth
                      />
                    </div>
                    <div className="text-right">
                      <button
                        className="bg-[#1C98ED] rounded-full p-2 text-1xl mt-2 w-fit px-8 shadow-md shadow-slate-500 my-4"
                        onClick={handleClose}
                        type="submit"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </Typography>
              </Box>
            </Modal> */}
            <button>
              <h2 className=" text-xl  lg:text-2xl bg-[#4BB5DE]  w-fit text-center p-2 rounded-sm px-12 headerFont">
                <a href="tel:+923444517167" className="headerFont">
                  Contact Us
                </a>
              </h2>
            </button>
          </div>
          <a
            href="mailto: info@amartechsolutions.com"
            className="flex flex-col items-center justify-center "
            data-aos="zoom-in"
          >
            <img
              src={EmailIcon}
              alt=""
              className="w-[60%] md:w-[35%] lg:w-[25%]"
            />

            <h2 className="text-xl lg:text-2xl bg-[#4BB5DE]  w-fit text-center p-2 rounded-sm px-12 headerFont">
              Drop us an Email
            </h2>
          </a>
        </div>
      </div>
    </>
  );
};

export default Contactus;
