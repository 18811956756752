import React, { useEffect, useState } from "react";
import BgVideo from "../assets/DataManagement/Video/DataManagement.mp4";

import Sec2Img from "../assets/DataManagement/Images/pexels-tima-miroshnichenko-6549629.jpg";

import arrow from "../assets/Backend/Icons/arrow.png";

import MainFooter from "../components/MainFooter";
import LeftAlignedTimeline from "../components/CustomizedTimeline";
import AMNavbar from "../components/AMNAvbar";
import strategey from "../assets/DataManagement/Icons/Strategy.png";
import wireframe from "../assets/DataManagement/Icons/Wireframes.png";
import development from "../assets/DataManagement/Icons/development.png";
import launch from "../assets/DataManagement/Icons/Launch.png";
import quality from "../assets/DataManagement/Icons/Quality.png";
import AOS from "aos";
import "aos/dist/aos.css";
const DataManagement = () => {
  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    let scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 500) {
      setScroll(true);
      // console.log(scrollValue);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    AOS.init({ duration: "1000" });

    window.scroll(0, 0);
  }, []);

  let planning = [
    {
      id: 1,
      title: "Infrastructure Planning",
      description: `We help you choose and specify
 hardware that suit your
 organizational pattern that brings
 you desired results`,
    },
    {
      id: 2,

      title: "Architecture Design",
      description: ` We suggest suitable frameworks
 and design your software
 architecture as per your business
 and vertical needs.`,
    },
    {
      id: 3,

      title: "Performance Tuning",
      description: ` We optimize and homogenize the
 performance of your databases to
 maximize use of resources and
 automating processes.`,
    },
    {
      id: 4,

      title: "Backup/Recovery Management",
      description: ` Our time-tested formula encrypts
 all your data reserves and ensures
 smooth backup and storage
 without risk of loss.`,
    },
    {
      id: 5,

      title: " Patch Management",
      description: ` We carry out regular and
 continuous patching activities to
 ensure no security threat and
 enforce bug control.`,
    },
    {
      id: 6,

      title: "Security Management",
      description: `We secure encryption keys,
 protection of the encryption
 system, access restriction
 backups and off-site backups.`,
    },
  ];
  return (
    <>
      <div className="bg-[#D2D2DB]">
        {/* ====================== >>> Section 1 Starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB] w-screen overflow-hidden ">
          <div
            className={`sticky top-0  z-10   ${
              scroll ? " bg-[#0000005a]" : "bg-transparent max-sm:bg-[#2787b7]"
            }`}
          >
            <AMNavbar />
          </div>
          <div className="relative -top-[6rem] left-0 ">
            <video
              src={`${BgVideo}`}
              autoPlay
              loop
              muted
              className=" object-cover h-[80vh] w-screen"
            ></video>
          </div>
          <div
            className="container mx-auto sm:w-[90%]  relative -top-[9rem] md:-top-[10rem] left-0 sm:h-10 h-fit "
            data-aos="fade-right"
          >
            <h1 className="text-white font-extrabold mx-4 text-5xl lg:text-6xl px-8  ">
              Database
            </h1>
            <h1 className="text-black  font-bold mx-4 text-5xl lg:text-6xl mt-4 lg:mt-0 px-8">
              Management
            </h1>
            <p className="text-black font-bold mx-4   text-2xl px-8 mt-2 ">
              Empowering businesses with robust database management solutions
              for streamlined data organization, access, and security.
            </p>
          </div>
        </div>
        {/* ====================== >>> Section 1 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 2 Starts Here <<<< ============================== */}
        <div className="bg-[#D2D2DB] h-full ">
          <div
            className="container w-screen  bg-white  mt-5   items-center 
          justify-center  overflow-hidden flex flex-col sm:flex-row mx-auto my-6 p-4 sm:p-0"
          >
            <img
              data-aos="fade-right"
              src={Sec2Img}
              alt="ImageHere"
              className="w-[150%] sm:w-[40%]   h-auto object-cover "
            />

            <div className=" container mx-8 my-4 bg ">
              <div className="flex flex-col items-start justify-center">
                <h1
                  className="my-1 text-sm xl:text-xl  text-black"
                  data-aos="fade-left"
                >
                  Our mission is to transform the maintenance of your
                  mission-critical applications into a seamless process,
                  allowing you to refocus on your core service offerings with
                  confidence and efficiency.
                </h1>
                <br />
                <h1
                  className="my-1 text-sm xl:text-xl  text-black"
                  data-aos="fade-left"
                >
                  We recognize the vision of data-driven businesses, boasting
                  unparalleled expertise in working with both open and closed
                  source databases. With a focus on modern data management
                  practice, we ensure optimal performance in today’s dynamic
                  landscape
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* ====================== >>> Section 2 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 3 Starts Here <<<< ============================== */}
        <div className="container mx-auto   bg-[#D2D2DB]    h-auto   overflow-hidden flex flex-col items-center justify-center py-12 ">
          <h1
            className=" text-2xl py-2 lg:text-2xl xl:text-2xl px-8 text-[#053F74] font-semibold futura_heavy  "
            data-aos="fade-left"
          >
            Experience the difference with Amar Tech – where excellence meets
            innovation
          </h1>
          <h3
            className="text-[#053F74]  text-xl   my-4 py-2 futura_book container w-[85%] "
            data-aos="fade-right"
          >
            Our Database Management expertise ensures a comprehensive suite of
            services aimed at driving high-performance operations, streamlining
            performances, while minimizing complexity and maximizing ROI.
          </h3>
          <div className="grid grid-rows-1 md:grid-rows-1 lg:grid-rows-1  container w-[85%] ms-8 ">
            <ul className="   list-disc text-md  md:text-base lg:text-xl xl:text-xl ">
              <li data-aos="fade-up" className=" text-[#053F74]">
                Proficiency in managing databases like MariaDB, including data
                modeling, optimization, and maintenance.
              </li>
              <li data-aos="fade-up" className=" text-[#053F74]">
                Ensure business data security with extremely functional Database
                Management Services
              </li>
              <li data-aos="fade-up" className=" text-[#053F74]">
                Ensure business data security with extremely functional Database
                Management Services
              </li>
            </ul>
          </div>
        </div>
        {/* ====================== >>> Section 3 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 4 Ends Here <<<< ============================== */}
        <div className="bg-[#D2D2DB] my-8 flex flex-col items-center justify-center">
          <div
            className="flex flex-col items-center justify-center"
            data-aos="fade-left"
          >
            <h1
              className="text-2xl text-black font-bold mx-10 tracking-wide container w-[85%] "
              data-aos="fade-right"
            >
              Streamlining Business Processes through Advanced Database
              Management Services Designed to Propel your Operations into the
              Future
            </h1>
            <p className="font-bold pt-2 pl-14 text-1xl text-black mx-10">
              Our cutting-edge solutions leverage the latest technology to
              optimize performance, ensuring seamless functionality and enhanced
              efficiency for your business
            </p>
          </div>
        </div>
        {/* ====================== >>> Section 4 Ends Here <<<< ============================== */}
        {/* ====================== >>> Section 5 Starts Here <<<< ============================== */}
        <div className="container w-[85%] bg-[#D2D2DB]   h-auto mx-auto my-14 ">
          <div className="container   max-sm:grid-cols-1 grid grid-cols-3 sm:grid-cols-3 grid-rows-2 items-center  rounded-2xl bg-[#F4F4F4] ">
            {planning.map((service, index) => {
              return (
                <div
                  data-aos="fade-up"
                  key={index}
                  className=" h-[100%] items-center justify-center py-6  "
                >
                  <div className="text-start ">
                    <h2 className="text-black  pl-6 text-xl sm:text-base md:text-lg xl:text-2xl  maven-pro-medium">
                      {service.title}
                    </h2>
                    <h2 className="border-[#46AAF3] ml-6 pt-2 border-b-4 w-24 " />
                  </div>

                  <p className="text-black text-xl font-medium   text-start pl-6 maven-pro-regular">
                    {service.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {/* ====================== >>> Section 5 End Here <<<< ============================== */}
        {/* ====================== >>> Section 6 Starts Here <<<< ============================== */}

        <div className="  bg-[#D2D2DB] databaseManagementSection6Img   h-auto   my-4">
          <div className="bg-white/80 w-full h-[100%] py-8">
            <h1
              className="text-3xl text-center   text-black font-semibold  futura_heavy  w-11/12 mx-auto"
              data-aos="zoom-in"
            >
              Why Choose Amar Tech for Database Management Service?
            </h1>
            <div className=" container mx-auto w-[60%] pt-6 ">
              <p className="text-xl  text-black">
                Amar Tech System isn't just a problem solver but a thought
                leader which means our innovation drive is high and we offer
                tailored solutions to every business problem, We guarantee:
              </p>
              <div className="grid grid-rows-1 md:grid-rows-1 lg:grid-rows-1 w-auto  ">
                <ul className="  ml-14 mt-2 list-disc text-sm sm:text-2xl maven-pro-regular ">
                  <li data-aos="fade-up" className=" text-black">
                    Advanced security integration for database management
                  </li>
                  <li data-aos="fade-up" className=" text-black">
                    Building best-in-class CMS and CRM development solutions
                  </li>
                  <li data-aos="fade-up" className=" text-black">
                    Seamless integration of several databases
                  </li>
                  <li data-aos="fade-up" className=" text-black">
                    Super coding structures and robust architectures
                  </li>
                  <li data-aos="fade-up" className=" text-black">
                    Lessened development time and optimized cost budget
                  </li>
                  <li data-aos="fade-up" className=" text-black">
                    Crafting dynamic experiences across business domains
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* ====================== >>> Section 6 Ends Here <<<< ==============================
             {/* ====================== >>> Section 7 starts Here <<<< ============================== */}

        {/* =============================================================================== */}
        <div className="bg-[#9AC2E5]  h-full ">
          <div className="text-center pt-8 ">
            <h1 className="text-4xl text-black futura_heavy">
              Database Management System Process
            </h1>
          </div>
          <div className="sm:w-[85%] mx-auto bg-[#9AC2E5] max-sm:grid-cols-1 max-sm:gap-4  rounded-md w-full h-auto grid grid-cols-1 lg:grid-cols-5  px-1  py-8">
            <div
              className="flex flex-col items-center justify-center"
              data-aos="zoom-in"
            >
              <img
                src={strategey}
                alt="ImageHere"
                className="h-[10rem] sm:h-[20rem] lg:h-[4rem] xl:h-[10rem] w-auto "
              />
              <h2 className="border-black border-b-2 w-24 pt-4 " />
              <h1 className="text-black text-1xl maven-pro-bold  pt-5 ">
                Strategy
              </h1>
            </div>
            <div
              className="flex flex-col items-center justify-center"
              data-aos="zoom-in"
            >
              <img
                src={wireframe}
                alt="ImageHere"
                className="h-[10rem] sm:h-[20rem] lg:h-[4rem] xl:h-[10rem] w-auto"
              />
              <h2 className="border-black   border-b-2 w-24 pt-4 " />
              <h1 className="text-black text-1xl maven-pro-bold  pt-5">
                Wireframes
              </h1>
            </div>
            <div
              className="flex flex-col items-center justify-center"
              data-aos="zoom-in"
            >
              <img
                src={development}
                alt="ImageHere"
                className="h-[10rem] sm:h-[20rem] lg:h-[4rem] xl:h-[10rem] w-auto"
              />
              <h2 className="border-black   border-b-2 w-24 pt-4 " />
              <h1 className="text-black text-1xl maven-pro-bold  pt-5">
                Development
              </h1>
            </div>
            <div
              className="flex flex-col items-center justify-center"
              data-aos="zoom-in"
            >
              <img
                src={quality}
                alt="ImageHere"
                className="h-[10rem] sm:h-[20rem] lg:h-[4rem] xl:h-[10rem] w-auto"
              />
              <h2 className="border-black   border-b-2 w-24 pt-4 " />
              <h1 className="text-black text-1xl maven-pro-bold  pt-5">
                Quality Test
              </h1>
            </div>
            <div
              className="flex flex-col items-center justify-center"
              data-aos="zoom-in"
            >
              <img
                src={launch}
                alt="ImageHere"
                className="h-[10rem] sm:h-[20rem] lg:h-[4rem] xl:h-[10rem] w-auto"
              />
              <h2 className="border-black   border-b-2 w-24 pt-4 " />
              <h1 className="text-black text-1xl maven-pro-bold  pt-5">
                Launch
              </h1>
            </div>
          </div>
        </div>
        {/* =============================================================================== */}
        <div className=" bg-[#D2D2DB] pt-12   h-auto  py-12 ">
          <h1 className="text-4xl text-center   text-black font-semibold  futura_heavy  w-11/12 mx-auto">
            How Amartech Organize A Smooth Workflow For Their Clients?
          </h1>
          <div className="pt-8 container sm:w-[95%] sm:mx-auto mx-2">
            <LeftAlignedTimeline />
          </div>
        </div>
        {/* =============================================================================== */}
        <div
          className={`bg-[#D2D2DB]  pt-4 pb-5 grid grid-col-1 justify-center`}
        >
          <div className="bg-[#053F74] xl:w-[750px] rounded-3xl p-4  flex flex-col items-center justify-center ">
            <h1 className="text-4xl max-sm:text-3xl text-center text-[#D2D2DB] font-bold pt-12 max-sm:pt-8 maven-pro-regular ">
              Want To Shape Your Business?
            </h1>
            <div className=" max-sm:h-36 rounded-md pt-8 max-sm:pt-4    ">
              <button className=" bg-[#d2d2db]  rounded-2xl  pl-5  text-2xl max-sm:text-2xl   flex flex-row text-[#FFFFFF] tomorrow-regular">
                Start Conversation Now
                <span>
                  <img src={arrow} alt="imageHere" className="w-14 pl-4" />
                </span>
              </button>
            </div>
          </div>
        </div>
        {/* =============================================================================== */}

        {/* ====================== >>> Section 7 Ends Here <<<< ============================== */}
        <div className=" bg-[#053F74]   p-4 w-100  h-auto mx-auto   mySubContainerClass">
          <MainFooter />
        </div>
      </div>
    </>
  );
};

export default DataManagement;
