import { Route, Routes } from "react-router-dom";

import "./App.css";
import FrontEnd from "./Screens/FrontEnd";
import Backend from "./Screens/Backend";
import DataManagement from "./Screens/DataManagement";
import DigitalTransformation from "./Screens/DigitalTransformation";
import Monitoring from "./Screens/Monitoring";
import Home from "./Home";
// import AMTimeline from "./components/AMTimeline";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/backend" element={<Backend />} />
        <Route path="/frontend" element={<FrontEnd />} />
        <Route path="/databasemanagement" element={<DataManagement />} />
        <Route
          path="/digitaltransformation"
          element={<DigitalTransformation />}
        />
        <Route path="/monitoring" element={<Monitoring />} />
      </Routes>
    </>
  );
}

export default App;
